import useUser from '@store/user/user-hook';
import { PopoverMenuAction } from '@components/popover-menu/use-popover-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faPaperclip,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import CaseFileViewModal, {
  CaseFileViewModalProps,
} from '@pages/app/rca/tabs/components/modals/case-file-manager/case-file-view-modal';
import { isLocationString } from '@util/string-util';
import { CaseFileListItemResponse } from '@api/types/case/case-file/case-file-list-item.response';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { useCallback, useMemo } from 'react';
import { useAppSelector } from '@store/store';
import { selectCurrentRcaCaseId } from '@store/rca-editor/selectors';
import { faExternalLink } from '@fortawesome/pro-light-svg-icons';
import { useCasePermission } from '@hooks/case/use-case-permission';
import config from 'src/config';
import { handleAuthenticatedDownload } from '@util/download-util';
import { selectUserTokens } from '@store/user/user-selectors';

interface Opt {
  onDelete?: (caseFile: CaseFileListItemResponse) => Promise<boolean>;
}

export default function useCaseFileMenuActions({ onDelete } = {} as Opt) {
  const { showPopup } = useUiPopup();
  const { canContribute } = useCasePermission();
  const caseId = useAppSelector(selectCurrentRcaCaseId);
  const userCompanyId = useUser()?.companyUserId;
  const tokens = useAppSelector(selectUserTokens);

  const getCaseFileMenuActions = useCallback(
    (caseFile: CaseFileListItemResponse) => {
      const { caseFileId, fileLocation, fileMetadataId, fileLength } = caseFile;
      const items: Array<PopoverMenuAction> = [
        {
          label: 'View Details',
          icon: <FontAwesomeIcon icon={faPaperclip} />,
          onClick: () => {
            showPopup<CaseFileViewModalProps, never>(CaseFileViewModal, {
              caseId: caseId,
              caseFileId: caseFileId,
              onDelete: onDelete ? () => onDelete(caseFile) : undefined,
            });
          },
        },
      ];

      if (fileLength && fileLength > 0) {
        items.push({
          label: 'Download File',
          icon: <FontAwesomeIcon icon={faDownload} />,
          onClick: () =>
            handleAuthenticatedDownload({
              url: `${config.apiUrl}/api/case/${caseId}/caseFile/file/${fileMetadataId}`,
              token: tokens?.accessToken,
            }),
        });
      } else if (isLocationString(fileLocation ?? '')) {
        items.push({
          label: 'Open Location',
          icon: <FontAwesomeIcon icon={faExternalLink} />,
          onClick: () => {
            window.open(fileLocation, '_blank');
          },
        });
      }

      if (
        caseFile.createdByCompanyUserId === userCompanyId &&
        onDelete != null &&
        canContribute
      ) {
        items.push({
          label: 'Delete file',
          icon: <FontAwesomeIcon icon={faTrash} />,
          onClick: () => onDelete(caseFile),
        });
      }

      return items;
    },
    [canContribute, caseId, onDelete, showPopup, userCompanyId]
  );

  return useMemo(
    () => ({
      getCaseFileMenuActions,
    }),
    [getCaseFileMenuActions]
  );
}
