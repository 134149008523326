import Row from '@components/layout-util-components/row';
import { BorderColor } from '@mui/icons-material';
import { Box, Typography, styled } from '@mui/material';
import { RcaUtil } from '@util/rca-util';
import { formatNumberToDecimals } from '@util/string-util';

interface IDashboardStatCaseProps {
  totalRcas: number;
  healthScore: number;
  completedRcas: number;
  completedHealthScore: number;
  inProgressRcas: number;
  inProgressHealthScore: number;
  titlePrefix?: string;
}

export const StyledStatBox = styled(Box)(({ theme: { palette } }) => ({
  borderRadius: 4,
  display: 'flex',
  background: palette.common.white,
  padding: '20px',
  flex: '1 0 0',
  alignSelf: 'stretch',
}));

export const StyledHealthScoreBox = styled(Box)(({ theme: { palette } }) => ({
  marginTop: '15px',
  flexGrow: 1,
  padding: '12px 5px',
  borderWidth: '1px',
  borderRadius: '5px',
  alignItems: 'center',
  justifyContent: 'center',
  borderStyle: 'solid',
  background: 'rgba(69,197,150,0.12)',
  borderColor: palette.success.main,
  '& .indicator': {
    background: palette.success.main,
  },
  '&.average': {
    background: 'rgba(255, 108, 0, 0.12)',
    borderColor: palette.warning.main,
    '& .indicator': {
      background: palette.warning.main,
    },
  },
  '&.not-applicable': {
    background: 'none',
    borderColor: '#D8D8E3',
    '& .indicator': {
      background: '#D8D8E3',
    },
  },
  '&.unhealthy': {
    background: 'rgba(255,61,61,0.12)',
    borderColor: palette.error.main,
    '& .indicator': {
      background: palette.error.main,
    },
  },
}));

export function DashboardStatCase({
  titlePrefix,
  totalRcas,
  healthScore,
  completedRcas,
  completedHealthScore,
  inProgressRcas,
  inProgressHealthScore,
}: IDashboardStatCaseProps) {
  const HealthScoreBox = ({ title, count, healthScore }: any) => {
    return (
      <StyledHealthScoreBox
        className={RcaUtil.getHealthStateFromScore(healthScore)}
      >
        <Typography
          sx={{
            fontSize: '10px',
            color: '#001927',
            textTransform: 'uppercase',
            fontWeight: 500,
            textAlign: 'center',
            marginBottom: '5px',
          }}
        >
          {title} ({count})
        </Typography>
        <Box
          sx={{
            flexShrink: 0,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              flex: '0 0 auto',
              width: '12px',
              height: '12px',
              borderRadius: 6,
              marginRight: '8px',
            }}
            className="indicator"
          />
          {formatNumberToDecimals(healthScore)}%
        </Box>
      </StyledHealthScoreBox>
    );
  };

  return (
    <StyledStatBox
      sx={{
        borderRadius: '10px',
        borderBottom: '1px solid #E7E8E6',
        display: 'flex',
        flexDirection: 'row !important',
        gap: '50px',
        '@media (max-width:1400px)': {
          gap: '20px',
        },
        '@media (max-width:1300px)': {
          flexDirection: 'column !important',
          gap: '10px',
        },
      }}
    >
      <Box>
        <Typography
          sx={{ marginBottom: '10px', lineHeight: '150%', color: '#555' }}
        >
          {titlePrefix} Health Score
        </Typography>
        <Typography
          variant="body1"
          sx={{ fontSize: '20px', lineHeight: '140%' }}
        >
          There {totalRcas === 1 ? 'is' : 'are'}{' '}
          <span className="highlight">{totalRcas}</span> RCA
          {totalRcas === 1 ? '' : 's'} achieving an average Health Score of{' '}
          <span
            style={{ color: RcaUtil.getHealthScoreColorFromScore(healthScore) }}
          >
            {formatNumberToDecimals(healthScore)}%
          </span>
          .
        </Typography>
      </Box>
      <Box
        sx={{
          flex: '0 0 50%',
        }}
      >
        <Typography sx={{ fontSize: '13px', color: '#949FA2' }}>
          Average Score Breakdown
        </Typography>
        <Row
          sx={{
            gap: '10px',
          }}
        >
          <HealthScoreBox
            count={completedRcas}
            title="Completed"
            healthScore={completedHealthScore}
          />
          <HealthScoreBox
            count={inProgressRcas}
            title="In Progress"
            healthScore={inProgressHealthScore}
          />
        </Row>
      </Box>
    </StyledStatBox>
  );
}
