import { ReactComponent as BreadcrumbBack } from '@assets/svgs/breadcrumb-back.svg';
import { styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Gap } from '@components/layout-util-components/gap';

interface BreadcrumbItem {
  label: string;
  path?: string;
}

interface Props {
  items: BreadcrumbItem[];
}

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  marginTop: -24,
});

const BreadcrumbContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 6,
});

const StyledButton = styled('button')(({ theme: { palette } }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 40,
  height: 40,
  background: 'transparent',
  border: '1px solid transparent',
  borderRadius: 4,
  flex: '0 0 auto',
  cursor: 'pointer',
  transition: 'border-color 0.2s ease-in-out',
  '&:hover': {
    borderColor: '#075A95',
  },
  '& svg path': {
    fill: '#075A95',
  },
}));

const BreadcrumbLink = styled(Typography)({
  fontSize: 13,
  color: '#001927',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const BreadcrumbText = styled(Typography)({
  fontSize: 13,
  color: '#666666',
});

export default function SubPageTitle({ items }: Readonly<Props>) {
  const navigate = useNavigate();

  return (
    <>
      <Container>
        <StyledButton type="button" onClick={() => navigate(-1)}>
          <BreadcrumbBack width={20} height={20} />
        </StyledButton>
        <BreadcrumbContainer>
          {items.map((item, index) => (
            <>
              {index > 0 && <ChevronRightIcon sx={{ color: '#666666' }} />}
              {item.path ? (
                <BreadcrumbLink
                  variant="body1"
                  onClick={() => item.path && navigate(item.path)}
                >
                  {item.label}
                </BreadcrumbLink>
              ) : (
                <BreadcrumbText variant="body1">{item.label}</BreadcrumbText>
              )}
            </>
          ))}
        </BreadcrumbContainer>
      </Container>
      {items.length > 0 && (
        <>
          <Gap size={32} />
          <Typography variant="h1">{items[items.length - 1].label}</Typography>
        </>
      )}
    </>
  );
}
