import { styled } from '@mui/material';
import InitialsContainerWithData, {
  InitialsContainerWithDataProps,
} from '@components/containers/initials-container-with-data';

const Container = styled('div')<{ isOpen: boolean }>(
  ({ isOpen, theme: { palette } }) => ({
    ...(isOpen && {}),
    gap: 10,
    '.circle-container': {
      fontSize: '13px',
      fontWeight: '800',
      lineHeight: '22px',
      letterSpacing: '0.05em',
      textAlign: 'center',
      backgroundColor: '#168664',
      opacity: '1',
      color: palette.common.white,
      marginLeft: isOpen ? 8 : -2,
    },

    '.title': {
      fontSize: '15px',
      fontWeight: '500',
      lineHeight: '21px',
      letterSpacing: '0.17000000178813934px',
      textAlign: 'left',
      color: '#000000DE',
    },

    '.subtitle': {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '17px',
      letterSpacing: '0.17000000178813934px',
      textAlign: 'left',
      color: '#000000DE',
    },
  })
);

export default function MenuItemInitialsContainer({
  isOpen,
  ...props
}: InitialsContainerWithDataProps & { isOpen: boolean }) {
  return (
    <Container isOpen={isOpen}>
      <InitialsContainerWithData {...props} />
    </Container>
  );
}
