import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from 'src/config';
import { apiFetchHandler } from '@api/api-fetch-handler';

const POSTMAN_HEADERS = !!process.env.REACT_APP_POSTMAN_API_KEY
  ? { 'x-api-key': process.env.REACT_APP_POSTMAN_API_KEY as string }
  : {};

const tagTypes = [
  'Case',
  'CaseAnalysisType',
  'CaseFile',
  'CaseGroup',
  'CaseImpact',
  'CaseImpactType',
  'CaseReport',
  'CaseTotals',
  'CaseUser',
  'ChainItem',
  'ChainItemCaseImpact',
  'ChainItemEvent',
  'ChainItemNote',
  'CompanyGroup',
  'CompanyLocation',
  'CompanyTheme',
  'CompanyUser',
  'Evidence',
  'Notification',
  'Outcome',
  'Skills',
  'Solution',
  'StorageItem',
  'Subscription',
  'TaskDetail',
  'User',
  'WACompany',
  'WAInstall',
] as const;

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiUrl}/api/`,
    credentials: 'include',
    headers: {
      ...(POSTMAN_HEADERS as {}),
    },
    fetchFn: apiFetchHandler,
    paramsSerializer: (params) => {
      let str = '';
      for (const paramsKey in params) {
        const val = params[paramsKey];
        if (val == null) {
          continue;
        }

        if (Array.isArray(val)) {
          for (const itemVal of val) {
            str += `${paramsKey}=${itemVal}&`;
          }
        } else {
          str += `${paramsKey}=${val}&`;
        }
      }
      if (str.length > 0) {
        str = str.substring(0, str.length - 1);
      }
      return str;
    },
  }),
  endpoints: () => ({}),
  refetchOnMountOrArgChange: false,
  keepUnusedDataFor: 3600,
  tagTypes,
});

// inter type for api.tagTypes
export type ApiTagType = (typeof tagTypes)[number];
