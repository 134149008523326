import ProfileLayoutScaffold, {
  ProfileLayoutTab,
} from '@components/layouts/app/scaffolds/profile-layout-scaffold';
import { isApp } from '@util/env';
import { useMemo } from 'react';

export default function ProfilePageLayout() {
  const tabs = useMemo(() => {
    const ret: Array<ProfileLayoutTab> = [];
    if (isApp) {
      ret.push({
        index: true,
        path: '/',
        name: 'Personal Details',
      });

      ret.push({
        path: 'preferences',
        name: 'Personal Settings',
      });

      ret.push({
        path: 'settings',
        name: 'Security',
        subPaths: ['settings/password', 'settings/mfa'],
      });

      ret.push({
        path: 'skills',
        name: 'Skills',
      });

      ret.push({
        path: 'kudos',
        name: 'Kudos Points',
      });
    }

    return ret;
  }, []);

  return (
    <ProfileLayoutScaffold
      baseRoute="profile"
      title="Your Profile"
      tabs={tabs}
    />
  );
}
