import Completer from 'async-completer';
import { TokenPairResponse } from '@api/types/auth/token-pair.response';
import {
  selectCouldUserInfoBeStale,
  selectUserTokens,
} from '@store/user/user-selectors';
import { store } from '@store/store';
import jwtDecode from 'jwt-decode';
import { refreshUserState } from '@store/user/user-slice';
import config from '../config';

let tokenRefreshCompleter: Completer<TokenPairResponse> | undefined;

const ignoreEndpoints = ['auth/login', 'auth/refresh', 'auth/revoke'];

export const apiFetchHandler = async (
  input: RequestInfo,
  init?: RequestInit | undefined
): Promise<Response> => {
  let requestHeaders: Headers;
  if (typeof input === 'string') {
    requestHeaders = new Headers(init?.headers);
  } else if (input instanceof Request) {
    requestHeaders = new Headers({
      ...init?.headers,
      ...input.headers,
    });
  } else {
    throw new Error('Invalid input type');
  }

  const isIgnoredEndpoint = ignoreEndpoints.some((endpoint) => {
    if (typeof input === 'string') {
      return input.toLowerCase().includes(endpoint);
    } else if (input instanceof Request) {
      return input.url.toLowerCase().includes(endpoint);
    }
    return false;
  });

  if (!requestHeaders.has('Content-Type')) {
    requestHeaders.set('Content-Type', 'application/json');
  }

  let modifiedInit = {
    ...init,
  };

  let currentTokenPair = selectUserTokens(store.getState());
  if (!isIgnoredEndpoint) {
    let bearer = currentTokenPair?.accessToken;

    let updatedTokens: TokenPairResponse | undefined;
    // If we're already refreshing, await it...
    if (tokenRefreshCompleter != null) {
      updatedTokens = (await tokenRefreshCompleter.promise)!;
    } else if (currentTokenPair != null) {
      updatedTokens = await handleTokenRefresh(currentTokenPair);
    }

    if (updatedTokens != null && updatedTokens.accessToken != null) {
      bearer = updatedTokens?.accessToken;
    }
    maybeAddTokenToHeaders(requestHeaders, bearer);

    modifiedInit = {
      ...modifiedInit,
      headers: Object.fromEntries(requestHeaders.entries()),
    };
  }

  const response = await fetch(input, modifiedInit);

  if (!isIgnoredEndpoint && response.headers.has('X-Force-Token-Refresh')) {
    // If it has the tokens in the body of the response, use them. Else make a call to the refresh endpoint
    if (response.ok) {
      let refreshedTokensResponseJson: any | undefined;
      try {
        refreshedTokensResponseJson = await response.clone().json();
      } catch (e) {}
      if (
        refreshedTokensResponseJson != null &&
        'accessToken' in refreshedTokensResponseJson &&
        'refreshToken' in refreshedTokensResponseJson
      ) {
        store.dispatch(refreshUserState(refreshedTokensResponseJson));
      }
    }
  }

  return response;
};

const handleTokenRefresh = async (
  currentTokenPair: TokenPairResponse
): Promise<TokenPairResponse> => {
  const dispatch = store.dispatch;

  // The user info could be stale if the user comes away from the browser for a while
  // and reloads the page. The tokens will be plucked from local storage so we'll want
  // to ensure these are refreshed. If they couldn't be state (i.e. they've recently been refreshed)
  // then we will check the expiry of the access token and only refresh if it's within 5 minutes of expiry
  const couldBeStale = selectCouldUserInfoBeStale(store.getState());
  if (!couldBeStale) {
    // Do we even need to refresh if it's greater than 5 minutes before expiry...?
    const accessTokenData = jwtDecode(currentTokenPair.accessToken) as any;
    const needsRefresh =
      accessTokenData.exp * 1000 - Date.now() < 5 * 60 * 1000;
    if (!needsRefresh) {
      return currentTokenPair;
    }

    // Are we even able to refresh.. i.e. do we have a refresh token
    const refreshToken = currentTokenPair.refreshToken;
    if (refreshToken == null) {
      return currentTokenPair;
    }
  }

  // trigger refresh

  tokenRefreshCompleter = new Completer<TokenPairResponse>();
  fetch(`${config.apiUrl}/api/auth/refresh`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      refreshToken: currentTokenPair.refreshToken,
    }),
  })
    .then((res) => res.json())
    .then((refreshedTokenPairs) => {
      tokenRefreshCompleter!.complete(refreshedTokenPairs);
      dispatch(refreshUserState(refreshedTokenPairs));
    })
    .catch((e) => {
      tokenRefreshCompleter!.completeError(e);
    })
    .finally(() => {
      tokenRefreshCompleter = undefined;
    });

  return tokenRefreshCompleter.promise as Promise<TokenPairResponse>;
};

const maybeAddTokenToHeaders = (headers: Headers, bearer?: string) => {
  if (bearer != null) {
    headers.set('Authorization', `Bearer ${bearer}`);
  }
  return headers;
};
