import requireAuth from '@components/hoc/with-required-auth-hoc';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@store/store';
import { Grid, Typography } from '@mui/material';
import { PrimaryButton } from '@components/buttons';
import ContactSupportLink from '@pages/auth/components/contact-support-link';
import { PageAlertDisplay } from '@components/alerts';
import { Gap } from '@components/layout-util-components/gap';
import useSelectCompany from '@pages/auth/select-company-hook';
import RadioSelectGroup from '@components/input/radio-select-group';
import { ButtonSkeleton, InlineBoxSkeleton } from '@components/skeletons';
import ListContainerSkeleton from '@components/skeletons/list-container-skeleton';

export interface SelectCompanyPageState {
  returnTo?: string;
}

function SelectCompanyPage() {
  const {
    isLoading,
    selectedCompany,
    companyOptions,
    canSubmit,
    isSubmitting,
    submit,
  } = useSelectCompany();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { state } = useLocation();
  const { returnTo } = (state || {}) as SelectCompanyPageState;

  const onSubmit = async () => {
    if (await submit()) {
      navigate(returnTo || '/');
    }
  };

  return (
    <Grid
      container
      direction="column"
      component="form"
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <Typography variant="h4">
        {isLoading ? <InlineBoxSkeleton width={150} /> : 'Select Company'}
      </Typography>
      <Gap size={4} />
      <PageAlertDisplay />
      <Gap size={20} />

      {isLoading ? (
        <ListContainerSkeleton count={4} />
      ) : (
        <RadioSelectGroup
          label="Available Companies"
          options={companyOptions!.map((company) => ({
            id: company.companyId,
            label: company.companyName,
          }))}
          value={selectedCompany.value}
          onChange={selectedCompany.set}
        />
      )}

      <Gap size={32} />
      {isLoading ? (
        <ButtonSkeleton />
      ) : (
        <PrimaryButton
          type="submit"
          disabled={!canSubmit}
          isBusy={isSubmitting}
          size="large"
        >
          Submit
        </PrimaryButton>
      )}

      <Gap size={32} />

      <ContactSupportLink />
    </Grid>
  );
}

export default requireAuth(SelectCompanyPage, { mfaRequired: false });
