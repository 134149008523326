import { Button, styled } from '@mui/material';
import { forwardRef, PropsWithChildren, ReactNode } from 'react';
import { ButtonProps } from './button-props';

type Props = ButtonProps & {
  badge?: ReactNode;
  className?: string;
  width?: number;
  height?: number;
};

const StyledButton = styled(Button)<{ width?: number; height?: number }>(
  ({ theme: { palette }, width = 35, height = 35 }) => ({
    display: 'inline-flex',
    padding: 0,
    minWidth: 'unset',
    minHeight: 'unset',
    width,
    height,
    borderRadius: '50%',
    flex: '0 0 auto',
    backgroundColor: 'white',
    color: palette.button.circle.color,
    border: `1px solid ${palette.common.grey40}`,
    boxShadow: 'none',

    '& > svg': {
      fill: palette.button.circle.color,
    },
  })
);

const BadgeContainer = styled('div')(({ theme: { palette } }) => ({
  position: 'absolute',
  right: -7,
  top: -7,
  flex: '0 0 auto',
  background: '#00000099',
  width: 18,
  height: 18,
  borderRadius: '50%',
  fontFamily: 'Roboto',
  fontSize: '12px',
  fontWeight: '600',
  lineHeight: '18px',
  letterSpacing: '0.15000000596046448px',
  textAlign: 'center',
  color: palette.common.white,
}));

const CircleButton = forwardRef<HTMLButtonElement, PropsWithChildren<Props>>(
  (
    { children, onClick, disabled, type, badge, className, width, height },
    ref
  ) => {
    return (
      <StyledButton
        ref={ref}
        variant="outlined"
        type={type ?? 'button'}
        onClick={onClick}
        disabled={disabled}
        className={className}
        width={width}
        height={height}
      >
        {children}
        {badge ? <BadgeContainer>{badge}</BadgeContainer> : null}
      </StyledButton>
    );
  }
);

export default CircleButton;
