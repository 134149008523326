import requireAuth from '@components/hoc/with-required-auth-hoc';
import useCompanyCaseImpactTypes from './company-case-impact-types-hook';
import ReferenceDataDisableList from './components/reference-data-disable-list';
import SubPageTitle from '@components/menu-items/subpage-title';
import { Gap } from '@components/layout-util-components/gap';

function CompanyCaseImpactTypesPage() {
  const {
    options,
    loadingCaseImpactTypes,
    enableCaseImpactType,
    disableCaseImpactType,
  } = useCompanyCaseImpactTypes();

  return (
    <>
      <SubPageTitle
        items={[
          { label: 'Company Profile', path: '/company-profile' },
          { label: 'RCA Settings', path: '/company-profile/rca-settings' },
          { label: 'Impact Category Configuration' },
        ]}
      />
      <Gap size={32} />
      <ReferenceDataDisableList
        options={options}
        isLoading={loadingCaseImpactTypes}
        onEnable={enableCaseImpactType}
        onDisable={disableCaseImpactType}
        type="impact category"
      />
    </>
  );
}

export default requireAuth(CompanyCaseImpactTypesPage);
