import { api } from '@api/api';
import { LoginRequest } from '@api/types/auth/login.request';
import { makeError } from '@api/types/api-error';
import { MfaCodeRequest } from '@api/types/auth/mfa-code.request';
import { MfaOptionsResponse } from '@api/types/auth/mfa-options.response';
import { MfaSubmitCodeRequest } from '@api/types/auth/mfa-submit-code.request';
import { RequestMfaOptInCodeRequest } from '@api/types/auth/request-mfa-opt-in-code.request';
import { clearUserState, refreshUserState } from '@store/user/user-slice';
import { OauthLoginRequest } from '@api/types/auth/oauth-login.request';
import { ChangeCompanyRequest } from '@api/types/auth/change-company.request';
import { SHORT_CACHE_DURATION } from '@api/cache-util';
import { LoginOptionsResponse } from '@api/types/auth/login-options.response';
import { TokenPairResponse } from '@api/types/auth/token-pair.response';

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<TokenPairResponse, LoginRequest>({
      query: (body) => ({
        url: 'auth/login',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(refreshUserState(data));
      },
      transformErrorResponse: (error) => makeError<LoginRequest>(error),
    }),
    refresh: build.mutation<TokenPairResponse, { refreshToken: string }>({
      query: (body) => ({
        url: 'auth/refresh',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        const { data } = await queryFulfilled;
        dispatch(refreshUserState(data));
      },
      transformErrorResponse: (error) => makeError<TokenPairResponse>(error),
    }),
    oauthLogin: build.mutation<void, OauthLoginRequest>({
      query: (body) => ({
        url: 'auth/oauth/login',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (error) => makeError<OauthLoginRequest>(error),
    }),
    logout: build.mutation<void, TokenPairResponse>({
      query: (body) => ({
        url: 'auth/revoke',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        await queryFulfilled;
        dispatch(clearUserState());
      },
      transformErrorResponse: (error) => makeError<never>(error),
    }),
    getMfaOptions: build.query<MfaOptionsResponse, void>({
      query: () => ({
        url: 'auth/twofactoroptions',
      }),
    }),
    getLoginOptionsOptions: build.query<LoginOptionsResponse, void>({
      query: () => ({
        url: 'auth/LoginMethods',
      }),
    }),
    request2FACode: build.query<void, MfaCodeRequest>({
      query: (body) => ({
        url: 'auth/requesttwofactorcode',
        method: 'POST',
        body,
      }),
    }),
    loginWith2FACode: build.mutation<void, MfaSubmitCodeRequest>({
      query: (body) => ({
        url: 'auth/loginwithtwofactorcode',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (error) => makeError<MfaSubmitCodeRequest>(error),
    }),
    changeCompany: build.mutation<void, ChangeCompanyRequest>({
      query: (body) => ({
        url: 'auth/changeCompany',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (error) => makeError<ChangeCompanyRequest>(error),
      invalidatesTags: [{ type: 'User', id: 'self' }, 'CompanyUser'],
    }),
    request2FAOptInCode: build.query<void, RequestMfaOptInCodeRequest>({
      query: (body) => ({
        url: 'auth/requestTwoFAOptInCode',
        method: 'POST',
        body,
      }),
      keepUnusedDataFor: SHORT_CACHE_DURATION,
      transformErrorResponse: (error) =>
        makeError<RequestMfaOptInCodeRequest>(error),
    }),
  }),
});

export const {
  useLoginMutation,
  useOauthLoginMutation,
  useLogoutMutation,
  useLoginWith2FACodeMutation,
  useGetMfaOptionsQuery,
  useLazyGetLoginOptionsOptionsQuery,
  useLazyRequest2FACodeQuery,
  useChangeCompanyMutation,
} = authApi;
