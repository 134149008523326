import requireAuth from '@components/hoc/with-required-auth-hoc';
import useCompanyCaseAnalysisTypes from './company-case-analysis-types-hook';
import ReferenceDataDisableList from './components/reference-data-disable-list';
import SubPageTitle from '@components/menu-items/subpage-title';
import { Gap } from '@components/layout-util-components/gap';

function CompanyCaseAnalysisTypesPage() {
  const {
    options,
    loadingCaseAnalysisTypes,
    enableCaseAnalysisType,
    disableCaseAnalysisType,
  } = useCompanyCaseAnalysisTypes();

  return (
    <>
      <SubPageTitle
        items={[
          { label: 'Company Profile', path: '/company-profile' },
          { label: 'RCA Settings', path: '/company-profile/rca-settings' },
          { label: 'RCA Type Configuration' },
        ]}
      />
      <Gap size={32} />
      <ReferenceDataDisableList
        options={options}
        isLoading={loadingCaseAnalysisTypes}
        onEnable={enableCaseAnalysisType}
        onDisable={disableCaseAnalysisType}
        type="Analysis Flow"
        sx={{
          borderRadius: '10px',
          borderBottom: '1px solid #E7E8E6',
        }}
      />
    </>
  );
}

export default requireAuth(CompanyCaseAnalysisTypesPage);
