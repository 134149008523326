import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { numberFromString } from '@util/string-util';
import secureLocalStorage from 'react-secure-storage';
import { TokenPairResponse } from '@api/types/auth/token-pair.response';

const SECURE_STORAGE_ACCESS_TOKEN_KEY = 'accessToken';
const SECURE_STORAGE_REFRESH_TOKEN_KEY = 'refreshToken';

export type CompanyRole = 'Admin' | string;

interface UserState {
  couldBeState?: boolean;
  token?: string;
  refreshToken?: string;
  userId?: string;
  companyUserId?: number;
  companyId?: number;
  requires2FA?: boolean;
  is2FAAuthenticated?: boolean;
  phoneNumberConfirmed?: boolean;
  firstName?: string;
  lastName?: string;
  permissions?: Array<String>;
  companyRole?: CompanyRole;
  createdCompany?: boolean;
  companyStatus?: string;
  iss?: string;
}

function userStateFromToken(tokenPair?: TokenPairResponse): UserState {
  const accessToken = tokenPair?.accessToken;
  if (!accessToken) {
    return {};
  }

  const decoded = jwtDecode(accessToken) as any;
  return {
    token: tokenPair?.accessToken,
    refreshToken: tokenPair?.refreshToken,
    userId: decoded.sub,
    companyUserId: !!decoded.companyUserId
      ? numberFromString(decoded.companyUserId)
      : undefined,
    companyId: !!decoded.companyId
      ? numberFromString(decoded.companyId)
      : undefined,
    requires2FA: decoded.requiresTwoFactorAuth === 'True',
    is2FAAuthenticated: decoded.isTwoFactorAuthenticated === 'True',
    createdCompany: decoded.createdCompany === 'True',
    phoneNumberConfirmed: decoded.phoneNumberConfirmed === 'True',
    firstName: decoded.firstName,
    lastName: decoded.lastName,
    permissions: Array.isArray(decoded.capabilities)
      ? decoded.capabilities
      : decoded.capabilities != null && typeof decoded.capabilities === 'string'
      ? JSON.parse(decoded.capabilities)
      : [],
    companyRole: decoded.companyRole,
    companyStatus: decoded.companyStatus,
    iss: decoded.iss,
  };
}

const initialState = (function (): UserState {
  const accessToken = secureLocalStorage.getItem(
    SECURE_STORAGE_ACCESS_TOKEN_KEY
  ) as string | undefined;

  const refreshToken = secureLocalStorage.getItem(
    SECURE_STORAGE_REFRESH_TOKEN_KEY
  ) as string | undefined;

  if (accessToken == null || refreshToken == null) {
    return {};
  }

  const accessTokenData = jwtDecode(accessToken) as any;
  const hasExpired = Date.now() > accessTokenData.exp * 1000;
  if (hasExpired) {
    secureLocalStorage.removeItem(SECURE_STORAGE_ACCESS_TOKEN_KEY);
    secureLocalStorage.removeItem(SECURE_STORAGE_REFRESH_TOKEN_KEY);
    return {};
  }

  return {
    ...userStateFromToken({ accessToken, refreshToken }),
    couldBeState: true,
  };
})();

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    refreshUserState: (_, { payload }: PayloadAction<TokenPairResponse>) => {
      secureLocalStorage.setItem(
        SECURE_STORAGE_ACCESS_TOKEN_KEY,
        payload.accessToken
      );
      secureLocalStorage.setItem(
        SECURE_STORAGE_REFRESH_TOKEN_KEY,
        payload.refreshToken
      );
      return {
        ...userStateFromToken(payload),
        couldBeState: false,
      };
    },
    clearUserState: () => {
      secureLocalStorage.removeItem(SECURE_STORAGE_ACCESS_TOKEN_KEY);
      secureLocalStorage.removeItem(SECURE_STORAGE_REFRESH_TOKEN_KEY);
      return {};
    },
  },
});

export default userSlice.reducer;

export const { refreshUserState, clearUserState } = userSlice.actions;
