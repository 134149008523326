import { Button, styled } from '@mui/material';
import { CSSProperties, PropsWithChildren } from 'react';
import { ButtonProps } from './button-props';
import { LoadingIndicator } from '@components/loading-indicator';

const StyledButton = styled(Button, { target: 'wct-primary-button' })<{
  buttonVariant?: Props['buttonVariant'];
  styleOptions?: CSSProperties;
  simulateDisabledState?: boolean;
}>(
  ({
    theme: { palette },
    buttonVariant,
    styleOptions,
    simulateDisabledState,
  }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none',
    boxShadow: 'none',
    backgroundColor: simulateDisabledState
      ? 'rgba(255, 108, 0, 0.5)'
      : 'rgba(255, 108, 0)',
    backgroundImage: simulateDisabledState
      ? 'none'
      : `linear-gradient(90deg, ${palette.primary.main} 0%, ${palette.primary.main} 100%)`,
    '&:hover': {
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      color: palette.blue.dark,
      backgroundColor: 'rgba(255, 108, 0, 0.5)',
      backgroundImage: 'none',
      opacity: 1,
      '& .button-content': {
        opacity: 0.8,
      },
    },
    '&.MuiButton-sizeLarge': {
      height: 56,
      fontSize: '15px',
      fontWeight: 500,
      borderRadius: 4,
    },
    '&.MuiButton-sizeMedium': {
      height: 40,
      fontSize: '14px',
      fontWeight: 400,
      borderRadius: 4,
    },
    ...styleOptions,
    ...((!buttonVariant || buttonVariant === 'one') && {
      color: palette.blue.dark,
    }),
    ...(buttonVariant === 'two' && {
      display: 'flex',
      padding: '8px 22px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
      color: palette.blue.dark,
    }),
  })
);

type Props = ButtonProps & {
  isBusy?: boolean;
  color?: 'primary' | 'info';
  size?: 'large' | 'medium' | 'small';
  buttonVariant?: 'one' | 'two';
  styleOptions?: CSSProperties;
  form?: string;
  simulateDisabledState?: boolean;
};

const PrimaryButton = ({
  id,
  children,
  onClick,
  disabled,
  color = 'primary',
  isBusy,
  type,
  buttonVariant,
  size,
  styleOptions,
  form,
  href,
  simulateDisabledState,
}: PropsWithChildren<Props>) => {
  return (
    <StyledButton
      id={id}
      variant="contained"
      buttonVariant={buttonVariant}
      type={type ?? 'button'}
      color={color}
      onClick={onClick}
      disabled={disabled || isBusy}
      size={size}
      styleOptions={styleOptions}
      form={form}
      href={href}
      simulateDisabledState={simulateDisabledState}
    >
      <span
        className="button-content"
        style={{ visibility: isBusy ? 'hidden' : 'visible' }}
      >
        {children}
      </span>
      <LoadingIndicator show={isBusy ?? false} isWhite />
    </StyledButton>
  );
};

export default PrimaryButton;
