import {
  useCompanyDeleteThemeMutation,
  useCompanyDisableThemeMutation,
  useCompanyEnableThemeMutation,
  useGetThemesQuery,
} from '@api/endpoints/company/company-theme.api';
import { ApiError } from '@api/types/api-error';
import { usePageAlertVariants } from '@components/alerts';
import { useCallback, useEffect, useState } from 'react';
import { ReferenceDataDisableListOption } from './components/reference-data-disable-list';

export default function useCompanyThemes() {
  const { showSuccessMessage, showErrorMessage } = usePageAlertVariants();
  const { data: themes, isLoading: loadingThemes } = useGetThemesQuery();
  const [options, setOptions] = useState<ReferenceDataDisableListOption[]>([]);
  const [enable] = useCompanyEnableThemeMutation();
  const [disable] = useCompanyDisableThemeMutation();
  const [deleteThemeMutation] = useCompanyDeleteThemeMutation();

  const enableTheme = (themeId: number) => {
    enable(themeId)
      .unwrap()
      .then(() => {
        showSuccessMessage('You have successfully enabled theme');
        return true;
      })
      .catch(({ message }: ApiError<never>) => {
        showErrorMessage(message);
        return false;
      });
  };

  const disableTheme = (themeId: number) => {
    disable(themeId)
      .unwrap()
      .then(() => {
        showSuccessMessage('You have successfully disabled theme');
        return true;
      })
      .catch(({ message }: ApiError<never>) => {
        showErrorMessage(message);
        return false;
      });
  };

  const deleteTheme = (themeId: number) => {
    deleteThemeMutation(themeId)
      .unwrap()
      .then(() => {
        showSuccessMessage('You have successfully deleted theme');
        return true;
      })
      .catch(({ message }: ApiError<never>) => {
        showErrorMessage(message);
        return false;
      });
  };

  const mapToOption = useCallback((o) => {
    return {
      id: o.themeId,
      label: o.name,
      enabled: !o.disabled,
      hasWarning: o.hasRCA,
      disabledDateTime: o.disabled ? new Date(o.disabled) : undefined,
      children: o.childThemes?.map(mapToOption) ?? [],
      colorId: o.colourId,
    };
  }, []);

  useEffect(() => {
    setOptions(themes?.filter((o) => !o.parentId).map(mapToOption) ?? []);
  }, [themes, mapToOption]);

  return {
    options,
    loadingThemes,
    enableTheme,
    disableTheme,
    deleteTheme,
  };
}
