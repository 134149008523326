import { ComponentType, useEffect, useMemo } from 'react';
import { Navigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import useUser from '@store/user/user-hook';
import { Permission } from '@api/types/permission';
import { useIsPath } from '@util/path-util';
import { isApp } from '@util/env';
import { useAppDispatch } from '@store/store';
import { ErrorPage } from '@components/error-handling/error-route-catcher';
import { useGetCompaniesForCurrentUserQuery } from '@api/endpoints/company-user.api';
import { FullScreenLoadingIndicator } from '@components/loading-indicator';
import jwtDecode from 'jwt-decode';

interface Args {
  mfaRequired?: boolean;
  permissions?: Array<Permission>;
}

export default function requireAuth<P>(
  Component: ComponentType<P>,
  { mfaRequired = true, permissions = [] }: Args = {}
) {
  return (props: P) => {
    const dispatch = useAppDispatch();
    const { pathname, search } = useLocation();

    const isMfaPath = useIsPath('/auth/mfa', { startsWith: true });
    const isLogoutPath = useIsPath('/auth/logout', { startsWith: true });
    const isSelectCompanyPath = useIsPath('/auth/select-company', {
      startsWith: true,
    });
    const isCreateCompanyPath = useIsPath('/auth/create-company', {
      startsWith: true,
    });

    const {
      tokenPair,
      isLoggedIn,
      userRequires2fa,
      hasPermission,
      isLoggedInAndFullyAuthenticated,
      hasSelectedCompany,
      logout,
    } = useUser();

    const { data: availableCompanies, isLoading: loadingUserCompanies } =
      useGetCompaniesForCurrentUserQuery(undefined, {
        skip: !isApp || !isLoggedIn,
      });

    const requiresSelectCompany =
      isApp &&
      !hasSelectedCompany &&
      availableCompanies != null &&
      availableCompanies.length > 0;

    const requiresCreateCompany =
      isApp && availableCompanies != null && availableCompanies.length === 0;

    const isLoading = loadingUserCompanies;

    useEffect(() => {
      const currentTokenPair = tokenPair;
      if (
        currentTokenPair == null ||
        currentTokenPair.refreshToken == null ||
        currentTokenPair.accessToken == null
      ) {
        logout();
        return;
      }

      const now = Date.now();
      const accessTokenData = jwtDecode(currentTokenPair.accessToken) as any;
      const accessTokenExpires = new Date(accessTokenData.exp * 1000);

      const hasExpired = now > accessTokenExpires.getTime();
      if (hasExpired) {
        logout();
      }
    }, [dispatch, isLoggedIn, logout, tokenPair]);

    const isPermissionGranted = useMemo(() => {
      if (!isLoggedIn) {
        return false;
      }

      if (permissions == null || permissions.length === 0) {
        return true;
      }

      for (const permission of permissions) {
        if (hasPermission(permission)) {
          return true;
        }
      }

      return false;
    }, [hasPermission, isLoggedIn]);

    if (isLogoutPath) {
      return <Component {...(props as any)} />;
    }

    if (!isLoggedIn) {
      return (
        <Navigate
          to={`/auth/login/${search}`}
          replace={true}
          state={{ returnTo: pathname }}
        />
      );
    }

    if (isLoading) {
      return <FullScreenLoadingIndicator />;
    }

    if (isLoggedInAndFullyAuthenticated && !isPermissionGranted) {
      return (
        <ErrorPage goTo={'/'} message={'This page does not seem to exist.'} />
      );
    }

    if (requiresSelectCompany) {
      if (isSelectCompanyPath) return <Component {...(props as any)} />;
      return (
        <Navigate
          to={`/auth/select-company/${search}`}
          replace={true}
          state={{ returnTo: pathname }}
        />
      );
    }

    if (requiresCreateCompany) {
      if (isCreateCompanyPath) return <Component {...(props as any)} />;
      return (
        <Navigate
          to={`/auth/create-company/${search}`}
          replace={true}
          state={{ returnTo: pathname }}
        />
      );
    }

    if (mfaRequired && userRequires2fa) {
      if (isMfaPath) return <Component {...(props as any)} />;
      return (
        <Navigate
          to={`/auth/mfa/${search}`}
          replace={true}
          state={{ returnTo: pathname }}
        />
      );
    }

    return <Component {...(props as any)} />;
  };
}
