import { useGetCompaniesForCurrentUserQuery } from '@api/endpoints/company-user.api';
import { useGetUserProfileQuery } from '@api/endpoints/user.api';
import { ReactComponent as CloseIcon } from '@assets/svgs/close.svg';
import { ReactComponent as Lock } from '@assets/svgs/lock.svg';
import { ReactComponent as Logout } from '@assets/svgs/logout.svg';
import { ReactComponent as Tasks } from '@assets/svgs/tasks.svg';
import Column from '@components/layout-util-components/column';
import { Divider } from '@components/layout-util-components/divider';
import { Gap } from '@components/layout-util-components/gap';
import Row from '@components/layout-util-components/row';
import Spacer from '@components/layout-util-components/spacer';
import MenuItemInitialsContainer from '@components/menu-items/app/menu-item-initials-container';
import { ButtonSkeleton } from '@components/skeletons';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { Popover, PopoverProps, styled, Typography } from '@mui/material';
import { useAppSelector } from '@store/store';
import useUser from '@store/user/user-hook';
import { selectIsLoggedInAndFullyAuthenticated } from '@store/user/user-selectors';
import { isAdmin, isApp } from '@util/env';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  isSideDrawerOpen?: boolean;
  hideInformation?: boolean;
  chevronRight?: boolean;
}

const Container = styled('div', { target: 'menu-item-profile' })<Props>(
  ({ isSideDrawerOpen, hideInformation }) => ({
    display: 'flex',
    width: '100%',
    height: '75px',
    padding: '15px 28px',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 5,
    cursor: 'pointer',
    alignSelf: 'stretch',
    background: '#fff',
    ...(isSideDrawerOpen && {
      background: '#fff',
    }),
    transition: 'all 0.2s ease-in-out',
  })
);

const StyledPopover = styled(Popover)<PopoverProps>({
  '&>.MuiPaper-root': {
    minWidth: 306,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: 0,
    borderRadius: '12px',
    boxShadow: '0px 0px 14px 0px rgba(0, 0, 0, 0.15)',
    paddingBottom: '10px',
    marginTop: '-48px',
    zIndex: 1,
  },
});

const ItemRow = styled('div')<{ noBottomMargin?: boolean }>(
  ({ noBottomMargin, theme: { palette } }) => ({
    display: 'flex',
    gap: 10,
    margin: `0 0 ${noBottomMargin ? 0 : 5}px 0`,
    padding: '10px 20px',
    alignSelf: 'stretch',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    '&:hover': {
      backgroundColor: '#F8F8FA',
    },
    '& > svg': {
      flex: '0 0 auto',
      height: 'auto',
      width: 16,
    },
  })
);

export default function MenuItemProfile(props: Readonly<Props>) {
  const { isSideDrawerOpen, hideInformation } = props;
  const navigate = useNavigate();
  const anchorEl = useRef<HTMLElement>();
  const [open, setOpen] = useState(false);

  const { showConfirmationModal } = useUiPopup();
  const { initials, fullName, logout, companyId } = useUser();

  const canFetchProfile = useAppSelector(selectIsLoggedInAndFullyAuthenticated);

  const { data: companies, isLoading: loadingCompanies } =
    useGetCompaniesForCurrentUserQuery(undefined, {
      skip: isAdmin || !canFetchProfile,
    });
  const { data: profile, isLoading: loadingProfile } = useGetUserProfileQuery(
    undefined,
    { skip: !canFetchProfile }
  );

  const selectedCompany = companies?.find((c) => c.companyId === companyId);

  const displayCompanySwitcher =
    isApp && (loadingCompanies || (companies != null && companies.length > 1));

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    anchorEl.current = e.currentTarget;
    setOpen(true);
  };

  const goto = (link: string) => {
    navigate(link);
    setOpen(false);
  };

  const onLogOut = async () => {
    const didLogout = await showConfirmationModal({
      title: 'Log out',
      message: 'Are you sure you would like to log out?',
      yesButtonLabel: 'Yes',
      noButtonLabel: 'No',
      yesButtonAsyncAction: logout,
    });

    if (!didLogout) {
      setOpen(false);
    }
  };

  return (
    <>
      <Container
        aria-describedby="profile-drawer-item"
        onClick={handleClick}
        {...props}
      >
        <MenuItemInitialsContainer
          isOpen={isSideDrawerOpen || false}
          loading={loadingProfile}
          label={hideInformation ? '' : fullName}
          initials={initials}
          subLabel={hideInformation ? '' : profile?.companyJobTitle ?? ' '}
          avatarSize={45}
        />
        {isSideDrawerOpen && <Spacer />}
      </Container>

      <StyledPopover
        id="profile-drawer-item"
        open={open}
        anchorEl={anchorEl.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Row
          sx={{
            padding: '20px',
            background:
              'linear-gradient(90deg, rgba(238, 230, 227, 0.60) 0%, rgba(218, 214, 215, 0.60) 100%)',
            '.close-btn': {
              width: 35,
              height: 35,
              borderRadius: 18,
              textAlign: 'center',
              lineHeight: 2,
              border: '1px transparent #fff',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'rgba(0, 34, 58, 0.04)',
                border: '1px solid #00223A',
              },
            },
          }}
        >
          <MenuItemInitialsContainer
            isOpen
            loading={loadingProfile}
            label={fullName}
            initials={initials}
          />
          <Spacer />
          <div className="close-btn" onClick={() => setOpen(false)}>
            <CloseIcon width={12} height={12} />
          </div>
        </Row>
        <Gap size={12} />
        {isApp && (
          <>
            <ItemRow onClick={() => goto('/profile')}>
              <Tasks />
              <Typography variant="body2">Edit Profile</Typography>
            </ItemRow>
          </>
        )}

        <ItemRow
          onClick={() =>
            goto(isApp ? '/profile/settings/password' : '/profile/password')
          }
        >
          <Lock />
          <Typography variant="body2">Update Password</Typography>
        </ItemRow>
        <Divider
          compact
          sx={{
            margin: '10px 0',
            border: 0,
            borderTop: '1px solid #EDEDED',
          }}
        />
        {displayCompanySwitcher ? (
          loadingCompanies || loadingProfile ? (
            <ButtonSkeleton />
          ) : (
            <ItemRow
              onClick={() => {
                goto('/auth/select-company');
              }}
            >
              <Tasks />
              <Column>
                <Typography variant="body2">Swap Company</Typography>
                {selectedCompany != null && (
                  <Typography variant="caption">
                    ({selectedCompany!.companyName})
                  </Typography>
                )}
              </Column>
            </ItemRow>
          )
        ) : null}

        <ItemRow noBottomMargin onClick={onLogOut}>
          <Logout />
          <Typography variant="body2">Logout</Typography>
        </ItemRow>
      </StyledPopover>
    </>
  );
}
