import {
  useGetSkillLinkedQuery,
  useGetSkillOptionsQuery,
  useUpdateSkillsLinksMutation,
} from '@api/endpoints/skills.api';
import { isApiError } from '@api/types/api-error';
import { Option } from '@api/types/option';
import { usePageAlertVariants } from '@components/alerts';
import { useCallback, useEffect, useState } from 'react';

export default function useSkillPage() {
  const [localSkills, setLocalSkills] = useState<Option[]>([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();
  const { data: skillsOptions, isLoading: loadingSkillsOptions } =
    useGetSkillOptionsQuery();
  const { data: skills, isLoading: loadingSkills } = useGetSkillLinkedQuery();
  const [updateSkillLinks, { isLoading: isSubmitting }] =
    useUpdateSkillsLinksMutation();

  // Initialize local skills when remote data loads
  useEffect(() => {
    if (skills) {
      setLocalSkills(skills);
    }
  }, [skills]);

  const selectSkill = (value: Option | Array<Option>) => {
    const newSkills = Array.isArray(value) ? value : [value];
    setLocalSkills(newSkills);
    setHasUnsavedChanges(true);
  };

  const removeSkill = (skillId: number) => {
    setLocalSkills((prev) => prev.filter((skill) => skill.id !== skillId));
    setHasUnsavedChanges(true);
  };

  const saveChanges = async () => {
    try {
      await updateSkillLinks(localSkills);
      setHasUnsavedChanges(false);
      // showSuccessMessage('Skills updated successfully');
      return true;
    } catch (e) {
      if (isApiError(e)) {
        showErrorMessage(e.message);
      }
      return false;
    }
  };

  const resetChanges = useCallback(() => {
    if (skills) {
      setLocalSkills(skills);
      setHasUnsavedChanges(false);
    }
  }, [skills]);

  const isLoading = loadingSkillsOptions || loadingSkills;

  return {
    skillsOptions: skillsOptions ?? [],
    skills: localSkills,
    selectSkill,
    removeSkill,
    saveChanges,
    resetChanges,
    isLoading,
    isSubmitting,
    hasUnsavedChanges,
  };
}
