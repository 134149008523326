/* eslint-disable jsx-a11y/alt-text */
import { useAppSelector } from '@store/store';
import { selectUserTokens } from '@store/user/user-selectors';
import React, { useEffect, useState } from 'react';

interface AuthImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  token?: string;
}

const imageCache = new Map<string, string>();

const AuthImage: React.FC<AuthImageProps> = ({ src, token, ...rest }) => {
  const tokens = useAppSelector(selectUserTokens);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const fetchImage = async () => {
      if (imageCache.has(src)) {
        setImageSrc(imageCache.get(src) || null);
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(src, {
          headers: {
            Authorization: `Bearer ${tokens?.accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch image: ${response.statusText}`);
        }

        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);

        imageCache.set(src, objectUrl);

        if (isMounted) {
          setImageSrc(objectUrl);
          setLoading(false);
        }
      } catch (e) {
        if (isMounted) {
          setError(true);
          setLoading(false);
        }
      }
    };

    fetchImage();

    return () => {
      isMounted = false;
    };
  }, [src, tokens]);

  if (loading) {
    return <></>;
  }

  if (error) {
    return <></>;
  }

  return <img src={imageSrc!} {...rest} />;
};

export default AuthImage;
