export const handleAuthenticatedDownload = async ({
  url,
  token,
  defaultFileName = 'download',
  openInNewTab = false,
}: {
  url: string;
  token?: string;
  defaultFileName?: string; // Fallback filename if none is found in headers
  openInNewTab?: boolean;
}) => {
  try {
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch file: ${response.statusText}`);
    }

    const blob = await response.blob();
    const objectUrl = URL.createObjectURL(blob);

    if (openInNewTab) {
      window.open(objectUrl, '_blank');
    } else {
      const contentDisposition = response.headers.get('Content-Disposition');
      const fileName = contentDisposition
        ? contentDisposition
            .split(';')
            .find((part) => part.trim().startsWith('filename='))
            ?.split('=')[1]
            ?.replace(/"/g, '')
        : null;

      const finalFileName = fileName || defaultFileName;

      const link = document.createElement('a');
      link.href = objectUrl;
      link.download = finalFileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    URL.revokeObjectURL(objectUrl);
  } catch (error) {
    console.error('Download failed:', error);
    throw error; // Optionally rethrow to handle at the call site
  }
};
