import { OutlinedButton, PrimaryButton } from '@components/buttons';
import OnboardingEntryContainer from '@components/containers/onboarding-entry-container';
import requireAuth from '@components/hoc/with-required-auth-hoc';
import Column from '@components/layout-util-components/column';
import { Gap } from '@components/layout-util-components/gap';
import Row from '@components/layout-util-components/row';
import FetchingIndicator from '@components/loading-indicator/fetching-indicator';
import { InputSkeleton } from '@components/skeletons';
import { faFileCertificate } from '@fortawesome/pro-light-svg-icons';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Skeleton, styled, Typography } from '@mui/material';
import { sidebarState } from '@store/sidebar-state-manager';
import { useEffect, useState } from 'react';
import { SkillsTypeaheadField } from './components/skills-typeahead-field';
import useSkillPage from './skills-page-hook';

const StyledSkillContainerBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: 25,
});

const StyledSkillBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  gap: '12px',
  padding: '20px 12px',
  paddingLeft: '50px',
  borderRadius: 8,
  background: '#fff',
  border: '1px solid rgba(0,0,0,0.25)',
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '0.16px',
  '&:hover': {
    border: '1px solid rgba(0,0,0,0.4)',
  },
  '&:hover button': {
    opacity: 1,
  },
}));

const StyledIconBox = styled(Box)({
  display: 'flex',
  width: '26px',
  height: '26px',
  flexDirection: 'column',
  justifyContent: 'center',
  '& button': {
    color: '#001825',
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '0.4px',
    borderRadius: '13px',
    background: '#F9F4F1',
    display: 'flex',
    width: '24px',
    height: '24px',
    justifyContent: 'center',
    alignItems: 'center',
    /** Only display close icon on hover of parent skill box */
    opacity: 0,
    transition: 'opacity',
    transitionTimingFunction: 'ease-out',
    transitionDuration: '100ms',
    '&:hover': {
      background: '#F1E3DB',
    },
  },
});

export const SkillPage = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(sidebarState.getState());
  const [footerStyle, setFooterStyle] = useState({});

  const {
    skills,
    isLoading,
    skillsOptions,
    selectSkill,
    removeSkill,
    saveChanges,
    resetChanges,
    isSubmitting,
    hasUnsavedChanges,
  } = useSkillPage();

  useEffect(() => {
    const unsubscribe = sidebarState.subscribe((isOpen) => {
      console.log('Sidebar state changed:', isOpen);
      setIsSidebarOpen(isOpen);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    setFooterStyle({
      position: 'fixed',
      height: 82,
      bottom: 0,
      left: 0,
      borderTop: '3px solid #ECEDF0',
      width: isSidebarOpen ? 'calc(100% - 275px)' : 'calc(100% - 80px)',
      maxHeight: '75px',
      padding: '20px 80px',
      backgroundColor: 'white',
      zIndex: 5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginLeft: isSidebarOpen ? '275px' : '80px',
      transition: 'all 0.1s ease-in-out',
    });
  }, [isSidebarOpen]);

  return (
    <Column>
      <Typography variant="h2">Skills</Typography>
      <Typography variant="body1">
        Add your skills to let colleagues find you and improve collaboration
        rates on RCAs.
      </Typography>
      <Gap size={12} />
      {isLoading ? (
        <Box maxWidth={250}>
          <InputSkeleton />
        </Box>
      ) : (
        <Row>
          <Box
            minWidth={300}
            sx={{
              '.MuiAutocomplete-root .MuiFilledInput-root .MuiAutocomplete-endAdornment':
                {
                  display: 'none',
                },
            }}
          >
            <SkillsTypeaheadField
              id="skills"
              name="skills"
              placeholder="Search skills"
              value={skills}
              onChange={selectSkill}
              options={skillsOptions}
              required
            />
          </Box>
          <Gap size={8} />
          <FetchingIndicator show={isSubmitting} />
        </Row>
      )}
      <Gap size={32} />
      {isLoading || skills.length > 0 ? (
        <StyledSkillContainerBox>
          {isLoading
            ? [...Array(4)].map((_, i) => (
                <Skeleton width="12rem" height="8rem" key={i} />
              ))
            : skills.map(({ id, label }) => (
                <StyledSkillBox key={id}>
                  <Typography>{label}</Typography>
                  <StyledIconBox>
                    <IconButton onClick={() => removeSkill(id)}>
                      <FontAwesomeIcon icon={faClose} />
                    </IconButton>
                  </StyledIconBox>
                </StyledSkillBox>
              ))}
        </StyledSkillContainerBox>
      ) : (
        <OnboardingEntryContainer
          icon={<FontAwesomeIcon icon={faFileCertificate} size="2x" />}
          title="Add your skills"
        />
      )}
      <div style={footerStyle}>
        <OutlinedButton onClick={() => window.history.back()}>
          Cancel
        </OutlinedButton>
        {/* <SecondaryButton onClick={resetChanges}>Reset</SecondaryButton> */}
        <PrimaryButton
          disabled={!hasUnsavedChanges}
          isBusy={isSubmitting}
          type="submit"
          onClick={saveChanges}
        >
          Save
        </PrimaryButton>
      </div>
    </Column>
  );
};

export default requireAuth(SkillPage);
