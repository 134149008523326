import { store, useAppDispatch, useAppSelector } from '@store/store';
import { useCallback, useMemo } from 'react';
import { getFullName, getInitials } from '@util/name-util';
import { isApp } from '@util/env';
import { useLogoutMutation } from '@api/endpoints/auth.api';
import { useNavigate } from 'react-router-dom';
import { usePageAlertVariants } from '@components/alerts';
import { api } from '@api/api';
import {
  selectIsLoggedInAndFullyAuthenticated,
  selectUserTokens,
} from '@store/user/user-selectors';
import { clearUserState } from '@store/user/user-slice';

export default function useUser() {
  const dispatch = useAppDispatch();

  const {
    token,
    refreshToken,
    userId,
    companyId,
    companyUserId,
    is2FAAuthenticated,
    requires2FA,
    firstName,
    lastName,
    permissions,
    companyRole,
    createdCompany,
  } = useAppSelector((state) => state.user);

  const isLoggedInAndFullyAuthenticated = useAppSelector(
    selectIsLoggedInAndFullyAuthenticated
  );

  const tokenPair = useMemo(() => {
    const currentToken = token;
    const currentRefreshToken = refreshToken;

    if (currentToken == null || currentRefreshToken == null) {
      return null;
    }

    return {
      accessToken: currentToken,
      refreshToken: currentRefreshToken,
    };
  }, [refreshToken, token]);

  const isLoggedIn = !!userId;
  const hasSelectedCompany = !isApp || !!companyId;

  const { showErrorMessage } = usePageAlertVariants();
  const navigate = useNavigate();
  const [logoutMutation, { isLoading: isLoggingOut }] = useLogoutMutation();

  const fullName = useMemo(() => {
    return getFullName(firstName, lastName);
  }, [firstName, lastName]);

  const initials = useMemo(() => {
    return getInitials(fullName);
  }, [fullName]);

  const hasPermission = useCallback(
    (permission: string) => {
      if (permissions == null || !Array.isArray(permissions)) {
        return false;
      }

      return permissions.indexOf(permission) > -1;
    },
    [permissions]
  );

  const logout = useCallback(async () => {
    const onRevoked = () => {
      dispatch(clearUserState());
      dispatch(api.util.resetApiState());
      navigate('/auth/login', {
        state: { prompt: 'logout-success', returnTo: '/', noRedirect: true },
      });
    };

    const tokenPair = selectUserTokens(store.getState());
    if (tokenPair == null) {
      onRevoked();
      return true;
    }

    try {
      logoutMutation(tokenPair).unwrap();
    } finally {
      onRevoked();
    }

    return true;
  }, [dispatch, logoutMutation, navigate]);

  return useMemo(
    () => ({
      isLoggedIn,
      hasSelectedCompany,
      isLoggedInAndFullyAuthenticated,
      userId,
      companyUserId,
      companyId,
      userRequires2fa: requires2FA && !is2FAAuthenticated,
      firstName,
      lastName,
      fullName,
      initials,
      hasPermission,
      isAdminUser: isApp && companyRole === 'Admin',
      isSuperAdminUser: isApp && companyRole === 'SuperAdmin',
      isAdminOrSuperAdminUser:
        isApp && (companyRole === 'Admin' || companyRole === 'SuperAdmin'),
      logout,
      createdCompany,
      tokenPair,
      isLoggingOut,
    }),
    [
      isLoggedIn,
      hasSelectedCompany,
      isLoggedInAndFullyAuthenticated,
      userId,
      companyUserId,
      companyId,
      requires2FA,
      is2FAAuthenticated,
      firstName,
      lastName,
      fullName,
      initials,
      hasPermission,
      companyRole,
      logout,
      createdCompany,
      tokenPair,
      isLoggingOut,
    ]
  );
}
