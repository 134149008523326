import { useGetCaseAnalysisTypesQuery } from '@api/endpoints/case-analysis-type.api';
import { useGetCasePriorityOptionsQuery } from '@api/endpoints/case-priority.api';
import { useGetCaseSeverityOptionsQuery } from '@api/endpoints/case-severity.api';
import { useGetCaseTypeOptionsQuery } from '@api/endpoints/case-type.api';
import {
  useCreateIncidentLocationMutation,
  useGetCaseDetailQuery,
  useUpsertCaseOverviewMutation,
} from '@api/endpoints/case.api';
import { useGetCompanyLocationDetailOptionsQuery } from '@api/endpoints/company-location.api';
import { useGetFrequencyTypeOptionsQuery } from '@api/endpoints/frequency-type.api';
import { ApiError } from '@api/types/api-error';
import { CaseIncidentResource } from '@api/types/case/case-incident.resource';
import { UpsertCaseOverviewRequest } from '@api/types/case/upsert-case-overview.request';
import { CompanyLocationResource } from '@api/types/company/company-location/company-location.resource';
import { usePageAlertVariants } from '@components/alerts';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import useField from '@hooks/use-field-hook';
import useFieldsWatcher from '@hooks/use-fields-watcher';
import useSystemText from '@hooks/use-system-text';
import FunctionalLocationForm, {
  FunctionalLocationFormProps,
} from '@pages/app/company-profile/forms/functional-location-form';
import { RCA_BOX_TEXT_MAX_LENGTH } from '@pages/app/rca/tabs/components/node-types/rca-default-node';
import { setCurrentCase } from '@store/create-analysis-process/create-analysis-slice';
import { selectCreateAnalysisType } from '@store/create-analysis-process/selectors';
import { manuallySetUserCaseRole } from '@store/rca-editor/rca-editor-slice';
import { useAppDispatch, useAppSelector } from '@store/store';
import { useBinaryOptions } from '@util/constant-options';
import { useIsPath } from '@util/path-util';
import { lessThan, onlyNumbers, required } from '@util/validators';
import { useEffect, useMemo, useState } from 'react';

export interface ICaseIncidentError {
  startDate?: string;
  endDate?: string;
}

export default function useOverviewStep(caseId?: number) {
  const isEdit = useIsPath('/rca/edit', { startsWith: true });
  const { showPopup } = useUiPopup();
  const { showErrorMessage, showSuccessMessage } = usePageAlertVariants();
  const dispatch = useAppDispatch();
  const { systemText } = useSystemText();

  const [caseIncidentErrors, setCaseIncidentErrors] =
    useState<ICaseIncidentError[]>();

  const { data: caseDetails } = useGetCaseDetailQuery(caseId ?? -1, {
    skip: caseId == null,
  });
  const { data: caseTypeOptions, isLoading: loadingCaseTypeOptions } =
    useGetCaseTypeOptionsQuery();
  const { data: priorityOptions, isLoading: loadingPriorityOptions } =
    useGetCasePriorityOptionsQuery();
  const { data: severityOptions, isLoading: loadingSeverityOptions } =
    useGetCaseSeverityOptionsQuery();
  const { data: frequencyTypeOptions, isLoading: loadingFrequencyTypeOptions } =
    useGetFrequencyTypeOptionsQuery();
  const {
    data: companyLocationOptions,
    isLoading: loadingCompanyLocationOptions,
  } = useGetCompanyLocationDetailOptionsQuery();

  const [incidentLocations, setIncidentLocations] = useState<
    Array<CompanyLocationResource>
  >([]);

  const { data } = useGetCaseDetailQuery(caseId ?? -1, {
    skip: caseId == null,
  });

  const [upsert, { isLoading: isSubmitting }] = useUpsertCaseOverviewMutation();

  const caseAnalysisType =
    useAppSelector(selectCreateAnalysisType) ?? data?.caseAnalysisTypeId;
  const { data: caseAnalysisTypes } = useGetCaseAnalysisTypesQuery({});
  const investigationName = useField<string>([required()], data?.name);
  const description = useField<string>([required()], data?.description);
  const focalPoint = useField<string>(
    [required(), lessThan(RCA_BOX_TEXT_MAX_LENGTH)],
    data?.focalPoint
  );
  const caseType = useField<Array<number>>(
    [required()],
    useMemo(() => data?.caseTypes.map(({ id }) => id) ?? [], [data?.caseTypes])
  );
  const priority = useField<number>([required()], data?.casePriorityId);
  const severity = useField<number>([required()], data?.caseSeverityId);
  const caseIncident = useField<CaseIncidentResource[]>(
    [required()],
    data?.caseIncident
  );
  const frequency = useField<string>(
    [onlyNumbers(), required()],
    data?.frequencyValue.toString()
  );
  const duration = useField<number>([required()], data?.frequencyTypeId);
  const frequencySummary = useField<string>([], data?.frequencyNotes);
  const reportingLocation = useField<CompanyLocationResource>(
    [required()],
    useMemo(() => {
      return data?.reportingLocation as CompanyLocationResource;
    }, [data?.reportingLocation])
  );

  const incidentAtReportingLocation = useField<boolean>(
    [],
    useMemo(() => {
      if (data == null) {
        return false;
      }

      return (
        data.reportingLocation.companyLocationId !==
        data.incidentLocation?.companyLocationId
      );
    }, [data])
  );

  const incidentLocation = useField<CompanyLocationResource>(
    [
      required({
        when: () => incidentAtReportingLocation.value === true,
      }),
    ],
    useMemo(() => {
      return data?.incidentLocation as CompanyLocationResource;
    }, [data?.incidentLocation])
  );

  const {
    isValid,
    isDirty,
    validateAll,
    scrollToTopMostError,
    projectedIsValid,
  } = useFieldsWatcher([
    investigationName,
    description,
    focalPoint,
    caseType,
    priority,
    severity,
    caseIncident,
    frequency,
    duration,
    frequencySummary,
    reportingLocation,
    incidentAtReportingLocation,
    incidentLocation,
  ]);

  const isLoading =
    loadingPriorityOptions ||
    loadingSeverityOptions ||
    loadingFrequencyTypeOptions ||
    loadingCompanyLocationOptions ||
    loadingCaseTypeOptions;

  const canSubmit =
    !isSubmitting && !isLoading && isValid && (!isEdit || isDirty);

  const binaryOptions = useBinaryOptions();

  const onSubmit = () => {
    if (!validateAll()) {
      scrollToTopMostError();
      return false;
    }

    return upsert({
      caseId,
      caseAnalysisTypeId: caseAnalysisType! as number,
      name: investigationName.value,
      description: description.value,
      focalPoint: focalPoint.value,
      caseTypes: caseType.value,
      casePriorityId: priority.value,
      caseSeverityId: severity.value,
      caseIncident: caseIncident.value,
      frequencyValue: +frequency.value,
      frequencyTypeId: duration.value,
      frequencyNotes: frequencySummary.value,
      reportingLocationId: reportingLocation.value.companyLocationId,
      sameLocationForIncident: !(incidentAtReportingLocation.value ?? false),
      incidentLocationId: incidentAtReportingLocation.value
        ? incidentLocation.value?.companyLocationId
        : reportingLocation.value.companyLocationId,
    })
      .unwrap()
      .then((resource) => {
        if (isEdit) {
          showSuccessMessage('Updated overview');

          // Hard reload so chain is fresh
          window.location.reload();
        } else {
          // This user created the case, set their case role to owner so permissions work down the line
          dispatch(manuallySetUserCaseRole('Owner'));
        }
        dispatch(setCurrentCase(resource));

        return true;
      })
      .catch(({ message, errors }: ApiError<UpsertCaseOverviewRequest>) => {
        showErrorMessage(errors?.caseId ?? errors?.chainItemId ?? message);

        investigationName.setError(errors?.name);
        description.setError(errors?.description);
        focalPoint.setError(errors?.focalPoint);
        caseType.setError(errors?.caseTypes);
        priority.setError(errors?.casePriorityId);
        severity.setError(errors?.caseSeverityId);
        let e: ICaseIncidentError[] = [];
        for (let x = 0; x < caseIncident.value.length; x++) {
          let error = { startDate: '', endDate: '' };
          if (errors && errors[`caseIncident[${x}].StartDate`]) {
            error.startDate = errors[`caseIncident[${x}].StartDate`];
          }
          if (errors && errors[`caseIncident[${x}].EndDate`]) {
            error.endDate = errors[`caseIncident[${x}].EndDate`];
          }
          e.push(error);
        }

        setCaseIncidentErrors(e);

        frequency.setError(errors?.frequencyValue);
        duration.setError(errors?.frequencyTypeId);
        frequencySummary.setError(errors?.frequencyNotes);
        reportingLocation.setError(errors?.reportingLocationId);
        incidentAtReportingLocation.setError(errors?.sameLocationForIncident);
        incidentLocation.setError(errors?.incidentLocationId);

        scrollToTopMostError();

        return false;
      });
  };

  // const endDateValue = endDate.value;
  // const startDateValue = startDate.value;
  // const validateStartDate = startDate.validate;
  // useEffect(() => {
  //   if (!isNullOrEmpty(startDateValue) && !isNullOrEmpty(endDateValue)) {
  //     validateStartDate(true);
  //   }
  // }, [endDateValue, validateStartDate, startDateValue]);

  const filteredCompanyLocationOptions = useMemo(() => {
    return (
      companyLocationOptions?.filter(
        (x) =>
          !x.disabled ||
          reportingLocation.value?.companyLocationId === x.companyLocationId ||
          incidentLocation.value?.companyLocationId === x.companyLocationId
      ) ?? []
    );
  }, [
    companyLocationOptions,
    incidentLocation.value?.companyLocationId,
    reportingLocation.value?.companyLocationId,
  ]);

  useEffect(() => {
    const incidentLocation = caseDetails?.incidentLocation;
    if (incidentLocation == null || companyLocationOptions == null) {
      return;
    }

    const isCompanyLocation = companyLocationOptions.some(
      (x) => x.companyLocationId === incidentLocation.companyLocationId
    );
    if (isCompanyLocation) {
      return;
    }

    setIncidentLocations((prev) => {
      const alreadyExists = prev.some(
        (x) => x.companyLocationId === incidentLocation.companyLocationId
      );
      if (alreadyExists) {
        return prev;
      }
      return [...prev, { ...incidentLocation, disabled: false }];
    });
  }, [caseDetails?.incidentLocation, companyLocationOptions]);

  const addNewIncidentLocation = async () => {
    const newIncidentLocation = await showPopup<
      FunctionalLocationFormProps,
      CompanyLocationResource
    >(FunctionalLocationForm, {
      allowPinOnMap: true,
      creationMutation: useCreateIncidentLocationMutation,
      isIncidentLocation: true,
    });

    if (newIncidentLocation != null) {
      setIncidentLocations((prev) => [...prev, newIncidentLocation]);
      incidentLocation.set(newIncidentLocation);
    }
  };

  return {
    investigationName,
    description,
    focalPoint,
    rcaType: caseType,
    priority,
    severity,
    caseIncident,
    caseIncidentErrors,
    frequency,
    duration,
    frequencySummary,
    reportingLocation,
    incidentAtReportingLocation,
    incidentLocation,
    binaryOptions,
    caseTypeOptions,
    frequencyTypeOptions,
    companyLocationOptions: filteredCompanyLocationOptions,
    incidentLocationOptions: [
      ...incidentLocations,
      ...filteredCompanyLocationOptions,
    ],
    priorityOptions,
    severityOptions,
    isValid,
    isLoading,
    isSubmitting,
    isEdit,
    canSubmit,
    projectedIsValid,
    systemText,
    addNewIncidentLocation,
    onSubmit,
    caseAnalysisTypeName:
      caseAnalysisTypes?.find((x) => x.caseAnalysisTypeId === caseAnalysisType)
        ?.name ?? '',
    setCaseIncidentErrors,
  };
}

export type OverviewStepState = ReturnType<typeof useOverviewStep>;
